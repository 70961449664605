export enum LocalStorage {
  EMPLOYEE_VIEW_MODE_LOCATION = 'employee-view-mode-location',
  BRAND_KEY = 'brand-key',
  SHOW_SIDEBAR = 'show-sidebar',
  SHOW_LEADS_STATS = 'show-leads-stats',
  SELECTED_LANGUAGE = 'selected-language',
  AUTH0_CYPRESS = 'auth0-cypress',
  PAGE_HAS_BEEN_FORCE_REFRESHED = 'page-has-been-force-refreshed',
  MGA_ORDER = 'mga-order',
  DATA_GRID_STATE = 'data-grid-state-',
  ASSORTMENT_VIEW = 'assortment-view',
}
